import {get, post, put, del } from "./http";
import router from "../router";
import axios from "axios";

// let url=process.env.VUE_APP_BASE_API=='http://m.sino-composite.com:8090/prod-test/'?'/api/':'/';
let url='';
if(process.env.VUE_APP_BASE_API=='http://m.sino-composite.com:8090/prod-test/'||process.env.VUE_APP_BASE_API=='http://m.sino-composite.com/prod-test/'){
  url='/api/'
}else{
  url='/'
}



// 获取当前页面的完整 URL
const currentURL = window.location.href;

// 使用正则表达式匹配 URL 中的查询参数部分
const parameterMatch = currentURL.match(/\?(.*)/);

if (parameterMatch) {
  // 如果有匹配到参数部分
  const queryString = parameterMatch[1];
  // 将查询参数字符串解析为对象
  const params = {};
  queryString.split('&').forEach(pair => {
    const [key, value] = pair.split('=');
    params[key] = decodeURIComponent(value);
  });
  // 使用 params 获取特定参数的值
  if(params.area_id=='QD'){
    localStorage.setItem('empArea','青岛');
  }else if(params.area_id=='JS'){
    localStorage.setItem('empArea','连云港');
  }else if(params.area_id=='CZ'){
    localStorage.setItem('empArea','科乐');
  }
}
let emp_url='';
let empArea=localStorage.getItem('empArea');
// console.log(empArea,'????000');

if(empArea=='青岛'||empArea=='QD'){
    emp_url=process.env.VUE_APP_BASE_QD=='http://m.sino-composite.com:8090/qd-api-test'?'/qd/':''
}else if(empArea=='连云港'||empArea=='JS'){
    emp_url=process.env.VUE_APP_BASE_JS=='http://m.sino-composite.com:8090/js-api-test'?'/js/':''
}else if(empArea=='科乐'||empArea=='CZ'){
  emp_url=process.env.VUE_APP_BASE_CZ=='http://m.sino-composite.com:8090/cz-api-test'?'/cz/':''
}
// console.log(emp_url,'222');

// console.log(localStorage.getItem('empUrl'));
//工厂模式
//登录
export const empLogin = params => post("home/login/login", params);
export const  authDeptcdSel= params => get(emp_url+"home/config/authDeptcdSel", params);

//车间分类下拉
export const deptCatSel = params => get(emp_url+"home/config/deptCatSel", params);
//设备管理
export const  deptSel= params => get(emp_url+"home/config/deptSel", params);
//零活类别
export const gxSel2 = params => get(emp_url+"home/config/gxSel", params);
//设备编号下拉
export const  machNoSel= params => get(emp_url+"home/config/machNoSel", params);
//维修人
export const  lhUserList= params => get(emp_url+"home/config/lhUserList", params);


//状态
export const  rApplyStatus= params => get(emp_url+"home/config/rApplyStatus", params);
//维修申请列表
export const  repairApplyList= params => get(emp_url+"home/mach/repairApplyList", params);
//审批流
export const  repairManageFlow= params => get(emp_url+"home/mach/repairManageFlow", params);
//申请详情
export const  repairApplyDetail= params => get(emp_url+"home/mach/repairApplyDetail", params);
//维修申请添加、编辑
export const  repairApplyEdit= params => post(emp_url+"home/mach/repairApplyEdit", params);
//维修申请单个附件删除
export const  repairFileDel= params => post(emp_url+"home/mach/repairFileDel", params);
//维修申请删除
export const  repairApplyDel= params => post(emp_url+"home/mach/repairApplyDel", params);

//维修申请审批提交
export const  repairApplyManage= params => post(emp_url+"home/mach/repairApplyManage", params);
//提交派工
export const  repairLineEdit= params => post(emp_url+"home/mach/repairLineEdit", params);
//派工详情
export const  repairApplyLine= params => get(emp_url+"home/mach/repairApplyLine", params);
//
export const  rLineStatus= params => get(emp_url+"home/config/rLineStatus", params);
//维修派工
export const  repairLineList= params => get(emp_url+"home/mach/repairLineList", params);
//工时详情
export const  machRepairHour= params => get(emp_url+"home/work_bao_gong/machRepairHour", params);


//用户信息
export const empUserInfo = params => get(emp_url+"home/index/loginInfo", params);
//退出登录
export const empLoginOut = params => get(emp_url+"home/index/loginOut", params);
//导航菜单
export const empMenu = params => get(emp_url+"home/config/sMenu", params);
//零活复审-已核实否
export const lhHeShiSel = params => get(emp_url+"home/config/heShiSel", params);
//生产异常耗时
export const heShiSel2 = params => get(emp_url+"home/config/heShiSel2", params);
//报工效率列表
export const empworkList = params => get(emp_url+"home/bao_gong/workList", params);
//现场申诉
//类型
export const xcAppealType = params => get(emp_url+"home/config/xcAppealType", params);
//状态
export const xcAppealStatus = params => get(emp_url+"home/config/xcAppealStatus", params);
//现场申诉列表
export const xcAppealList = params => get(emp_url+"home/appeal/xcAppealList", params);
//删除现场申诉
export const xcAppealDel = params => post(emp_url+"home/appeal/xcAppealDel", params);
//添加申诉-下拉
export const managerSel = params => get(emp_url+"home/appeal/managerSel", params);
//提交申诉
export const addXcAppeal = params => post(emp_url+"home/appeal/addXcAppeal", params);
//审核进度
export const appealflowList = params => get(emp_url+"home/appeal/flowList", params);
//现场审核列表
export const xcManageList = params => get(emp_url+"home/appeal/xcManageList", params);
//现场审核
export const xcAppealDetail = params => get(emp_url+"home/appeal/xcAppealDetail", params);
//现场审核提交
export const XcAppealManage = params => post(emp_url+"home/appeal/xcAppealManage", params);
//工时申诉-状态
export const timeAppealStatus = params => get(emp_url+"home/config/timeAppealStatus", params);
//工时申诉-列表
export const timeAppealList = params => get(emp_url+"home/appeal/timeAppealList", params);
//删除工时申诉
export const timeAppealDel = params => post(emp_url+"home/appeal/timeAppealDel", params);
//添加工时
export const workHourList = params => get(emp_url+"home/appeal/workHourList", params);
//添加工时申诉
export const addTimeAppeal = params => post(emp_url+"home/appeal/addTimeAppeal", params);
//工时申诉审核
export const timeManageList = params => get(emp_url+"home/appeal/timeManageList", params);
//工时审核获取数据
export const timeAppealDetail = params => get(emp_url+"home/appeal/timeAppealDetail", params);
//工时审核提交
export const timeAppealManage = params => post(emp_url+"home/appeal/timeAppealManage", params);
//派工列表
export const workLeftList = params => get(emp_url+"home/gx_pai_gong/workLeftList", params);
//派工列表-明细
export const workLeftDetail = params => get(emp_url+"home/gx_pai_gong/workLeftDetail", params);
//出勤推送列表
export const chuQinList = params => get(emp_url+"home/user/chuQinList", params);
//出勤推送
export const chuQinTuiSong = params => get(emp_url+"home/user/chuQinTuiSong", params);
//重新汇总
export const chuQinHuiZong = params => get(emp_url+"home/user/chuQinHuiZong", params);
//来源下拉
export const  exceptLY= params => get(emp_url+"home/config/exceptLY", params);
//已核实否
export const  wag200CState= params => get(emp_url+"home/config/wag200CState", params);
//异常初审列表
export const  exceptionHfList= params => get(emp_url+"home/bao_gong/exceptionHfList", params);
//生产异常耗时核实-退回
export const  exceptionHfTuiHui= params => post(emp_url+"home/bao_gong/exceptionHfTuiHui", params);
//异常耗时初审-保存
export const  exceptionHfEdit= params => post(emp_url+"home/bao_gong/exceptionHfEdit", params);

//异常耗时复审列表
export const  exceptionHsList= params => get(emp_url+"home/bao_gong/exceptionHsList", params);
//异常耗时复审保存
export const  exceptionHsEdit= params => post(emp_url+"home/bao_gong/exceptionHsEdit", params);
//异常耗时复审退出
export const  exceptionHsTuiHui= params => post(emp_url+"home/bao_gong/exceptionHsTuiHui", params);
//零活复审列表
export const lingHuoSList = params => get(emp_url+"home/bao_gong/lingHuoSList", params);
//复审保存
export const lingHuoSEdit = params => post(emp_url+"home/bao_gong/lingHuoSEdit", params);
//复审退回
export const lingHuoTuiHui = params => post(emp_url+"home/bao_gong/lingHuoTuiHui", params);
//总报工时
export const zongBaoTime = params => get(emp_url+"home/bao_gong/zongBaoTime", params);
//出勤工时
export const chuQinTime = params => get(emp_url+"home/bao_gong/chuQinTime", params);
//获取对应扣除的时间
export const kouChuTime = params => get(emp_url+"home/bao_gong/kouChuTime", params);
//异常耗时列表
export const exceptionHourList = params => get(emp_url+"home/bao_gong/exceptionHourList", params);
//计算扣除时间
export const kouChuExceptTime = params => get(emp_url+"home/bao_gong/kouChuExceptTime", params);
//异常耗时-退回
export const exceptionHourTuiHui = params => post(emp_url+"home/bao_gong/exceptionHourTuiHui", params);
//异常耗时-保存
export const exceptionHourEdit = params => post(emp_url+"home/bao_gong/exceptionHourEdit", params);
//请假状态
export const empapplyStatusSel = params => get(emp_url+"home/config/applyStatusSel", params);
//请假列表
export const myApplyList = params => get(emp_url+"home/leave_apply/myApplyList", params);
//请假-审批流
export const empflowList = params => get(emp_url+"home/leave_apply/flowList", params);
//请假-销假详情
export const emprevokeData = params => get(emp_url+"home/leave_apply/revokeData", params);
//提交请假
export const addApply = params => post(emp_url+"home/leave_apply/addApply", params);
//提交销假申请
export const addRevoke = params => post(emp_url+"home/leave_apply/addRevoke", params);
//删除请假申请
export const applyDel = params => post(emp_url+"home/leave_apply/applyDel", params);
//审批列表
export const empmanageList = params => get(emp_url+"home/leave_apply/manageList", params);
//审批列表-审批详情
export const empapplyInfo = params => get(emp_url+"home/leave_apply/applyInfo", params);
//审批列表-提交审批
export const empaddManage = params => post(emp_url+"home/leave_apply/addManage", params);
//全部申请列表
export const emptotalList = params => get(emp_url+"home/leave_apply/totalList", params);
//
export const lingHuoFList = params => get(emp_url+"home/bao_gong/lingHuoFList", params);
//
export const  lingHuoFEdit= params => post(emp_url+"home/bao_gong/lingHuoFEdit", params);

// export const  heShiSel= params => get(url+"home/config/heShiSel", params);









//首页
export const index = params => get(url+"home/index/index", params);
//销售额
export const indexSale = params => get(url+"home/index/indexSale", params);
//登录
export const goLogin = params => post(url+"home/login/login", params);
//修改密码
export const setPassword = params => post(url+"home/user/setPassword", params);

//生成二维码
export const goWe = params => get(url+"home/we_chat/login_param", params);
//获取用户信息
export const userInfo = params => get(url+"home/user/user_info", params);
//导航菜单
export const menu = params => get(url+"Home/config/menu", params);
//退出登录
export const loginOut = params => get(url+"home/login/login_out", params);
//物品列表
export const goodsList = params => get(url+"home/office_product/productList", params);
//分类列表
export const productCatSel = params => get(url+"home/config/productCatSel", params);
//添加物品
export const addGoods = params => post(url+"home/office_product/editProduct", params);
//地区列表
export const areaList = params => get(url+"home/office_product/area_list", params);
//上货
export const addProduct = params => post(url+"home/office_product/editProductStock", params);
//库存列表
export const stockList = params => get(url+"home/office_product/stockList", params);
//库存列表-编辑预警库存
export const editRedStock = params => post(url+"home/office_product/editRedStock", params);
//机器编号 售货机
export const ropotId = params => get(url+"home/office_product/machineList", params);
//货道号
export const aisId = params => get(url+"home/office_product/slotNoList", params);
//货道更新
export const newAis = params => post(url+"home/office_product/editSlot", params);
//上下架
export const setStock = params => post(url+"home/office_product/setInStock", params);
//库存日志列表
export const getStocklog = params => get(url+"home/office_product/stockLogList", params);
//售货机
export const getMachine = params => get(url+"home/office_product/machineList", params);
//货道列表
export const  getSlotList= params => get(url+"home/office_product/slotList", params);

//指定物品货道库存详情
export const  getStockDetail= params => get(url+"home/office_product/slotStockList", params);
//线下物品领取列表
export const  getGoodsRece= params => get(url+"home/office_product/inStockList", params);
//提交线下订单
export const  submitBuy= params => post(url+"home/office_product/buyOffline", params);
//获取线上/线下订单列表 
export const  getOrderList= params => get(url+"home/office_product/order_list", params);
//订单状态
export const  orderStatus= params => get(url+"home/config/product_order_status", params);
//删除线下订单列表
export const  orderDetele= params => post(url+"home/office_product/orderDelete", params);
//订单管理列表
export const  orderManageList= params => get(url+"home/office_product/order_manage", params);
//线下确认领取
export const  orderConfirm= params => post(url+"home/office_product/orderConfirm", params);
//线上订单确认
export const  orderOnConfirm= params => post(url+"home/office_product/orderStatusConfirm", params);
//物品详情
export const  productDetail= params => get(url+"home/office_product/productDetail", params);
//权限列表
export const  getAuthList= params => get(url+"home/rbac/auth_list", params);
//权限编辑
export const  editAuthList= params => post(url+"home/rbac/auth_edit", params);
//角色列表
export const  getRoleList= params => get(url+"home/rbac/role_list", params);
//角色编辑
export const  editRole= params => post(url+"home/rbac/role_edit", params);
//用户列表
export const  getUserList= params => get(url+"home/rbac/user_list", params);
//角色绑定用户
export const  getUserBind= params => post(url+"home/rbac/user_bind", params);
//角色绑定权限列表
export const  getAuthbindList= params => get(url+"home/rbac/role_auth_list", params);
//角色绑定权限
export const  getAuthBind= params => post(url+"home/rbac/auth_bind", params);
//移动端物品领取
export const  productOnline= params => get(url+"home/office_product/product_online", params);
//提交订单
export const  buyOnline= params => post(url+"home/office_product/buy_online", params);
//同乘人员
export const  userSelList= params => get(url+"home/user/userSelList", params);
//公用-行驶记录页面数据
export const  getCarDetail= params => get(url+"home/car/getUsedDetail", params);
//行驶记录列表
export const  getPublicList= params => get(url+"home/car/publicUsedList", params);
//发送审核详情-推送
export const  sendApprDetail= params => post(url+"home/car_admin/sendApprDetail", params);
//行驶记录详情-
export const  getPublicDetail= params => get(url+"home/car/getCarPubDetail", params);
//私用行驶记录提交
export const  addPrivateUsed= params => post(url+"home/car/addPrivateUsed", params);
//车辆状态列表
export const  getCarStatusList= params => get(url+"home/car/carStatusList", params);
//车辆状态地区
export const  carAreaSel= params => get(url+"home/config/carAreaSel", params);

//公务提交
export const  addPublicUsed= params => post(url+"home/car/addPublicUsed", params);
//路线请求
export const  drivingList= params => get(url+"home/car/getRoutePlan", params);
// //生成静态地图
// export const  staticImage= params => get("staticimage/v2", params);
//公务审核详情
export const  publicAudit= params => post(url+"home/car_admin/getCarAppr", params);
//公务车辆审核
export const  submitAudit= params => post(url+"home/car_admin/carApprStatus", params);
//公车审核列表
export const  getPublicAuthList= params => get(url+"home/car_admin/publicApprList", params);
//公车列表
export const  publicList= params => get(url+"home/car/publicList", params);
//公车申请-区域司机列表
export const  areaDriverList= params => post(url+"home/car/areaDriverList", params);
//提交公车申请
export const  addPublicApply= params => post(url+"home/car/addPublicApply", params);
//提交补充公车申请
export const  addPublicSupp= params => post(url+"home/car/addPublicSupp", params);
//公车-选车/换车
export const  chooseCar= params => post(url+"home/car/chooseCar", params);
//公用-乘车详情
export const  getPassengerDetail= params => get(url+"home/car/getPassengerDetail", params);
//公车删除
export const  delPublic= params => post(url+"home/car/delPublic", params);
//补充公车申请
export const  suppPublicApply= params => get(url+"home/car/suppPublicApply", params);
//公用申请-乘车列表
export const  passengerList= params => get(url+"home/car/passengerList", params);
//公用申请-乘车列表-取消
export const  cancelPass= params => post(url+"home/car/cancelPass", params);
//公用申请-乘车列表-分配司机
export const  allotDriver= params => post(url+"home/car/allotDriver", params);
//管理员可用车辆列表
export const  carAdminList= params => get(url+"home/car_admin/useCarList", params);
//用户可用车辆列表
export const  carList= params => get(url+"home/car/useCarList", params);
//私用车辆申请列表
export const  getPrivateList= params => get(url+"home/car/applyPrivateList", params);
//点击私用申请判断
export const  checkPrivate= params => get(url+"home/car/checkPrivate", params);
//私用还车提醒
export const  privateReturnTip= params => post(url+"home/car_admin/privateReturnTip", params);
//公用还车提醒
export const  publicReturnTip= params => post(url+"home/car_admin/publicReturnTip", params);
//错误日志
export const  apacheLog= params => get(url+"home/system/apacheLog", params);
//运行日志
export const  runtimeList= params => get(url+"home/System/runtimeList", params);
//运行日志查看
export const  runtimeRead= params => get(url+"home/System/runtimeRead", params);
//帮助文档
export const  helpDocList= params => get(url+"home/user/helpDocList", params);





//添加私用申请
export const  addPrivate= params => post(url+"home/car/addApplyPrivate", params);
//私用申请-行驶记录-蓝
export const  checkPriDrivingRecord= params => get(url+"home/car/checkPriDrivingRecord", params);
//私用申请-行驶记录-红
export const  priDrivingRecordDetaild= params => get(url+"home/car/priDrivingRecordDetail", params);
//获取消耗积分和资金
export const getNumsAndMoney = params => post(url+"home/car/getNumsAndMoney", params);
//私用申请-开钥匙柜
export const openKey = params => post(url+"home/car/openKeyBox", params);
//私用申请-删除
export const delPrivate = params => post(url+"home/car/delPrivate", params);
//私用申请-管理员介入
export const addAdminDispose = params => post(url+"home/car/addAdminDispose", params);
//私用申请行驶记录-图片上传
export const addFile = params => post(url+"home/car/addFile", params);
//私用行驶记录
export const privateUsedList = params => get(url+"home/car/privateUsedList", params);
//私用行驶记录-车费确认
export const moneyConfirm = params => post(url+"home/car_admin/moneyConfirm", params);
//私用行驶记录-费用单打印
export const moneyPrint = params => post(url+"home/car_admin/moneyPrint", params);
//私用行驶记录-编辑ETC
export const editEtc = params => post(url+"home/car_admin/editEtc", params);
//提前私用申请列表
export const managePrivate = params => post(url+"home/car/managePrivate", params);
/*
管理员
*/
//借车审批
export const browApprList = params => get(url+"home/car_admin/browApprList", params);
//提交审批
export const browApprStatus = params => post(url+"home/car_admin/browApprStatus", params);
//乘车审批
export const passApprList = params => get(url+"home/car_admin/passApprList", params);
//乘车审批-处理
export const dealPassStatus = params => post(url+"home/car_admin/dealPassStatus", params);
//钥匙柜
export const cabinetList = params => get(url+"home/car_admin/cabinetList", params);
//钥匙列表
export const keysList = params => get(url+"home/car_admin/keysList", params);
//钥匙列表-开锁
export const unlockCabinet = params => post(url+"home/car_admin/unlockCabinet", params);
//管理员介入
export const adminInter = params => get(url+"home/car_admin/adminInter", params);
//管理员介入-审核
export const adminAppr = params => post(url+"home/car_admin/adminAppr", params);
//修正记录
export const updateRecode = params => get(url+"home/car_admin/updateRecode", params);
//修正记录详情
export const  recordDetail= params => get(url+"home/car_admin/recordDetail", params);
//司机列表
export const  driverList= params => get(url+"home/car_admin/driverList", params);
//添加司机
export const  addDriver= params => post(url+"home/car_admin/addDriver", params);
//删除司机
export const  delDriver= params => post(url+"home/car_admin/delDriver", params);
//区域列表
export const  addressList= params => get(url+"home/car/areaList", params);
//导出预算
export const  budgetSearch= params => get(url+"home/car_admin/budgetSearch", params);

//车辆信息-车辆列表
export const  adminCarList= params => get(url+"home/car_admin/carList", params);
//车辆信息-列表-添加
export const  addCarInfo= params => post(url+"home/car_admin/addCarInfo", params);
//车辆信息-列表-编辑
export const  editCarInfo= params => post(url+"home/car_admin/editCarInfo", params);
//车辆列表导出
export const  carBudgetExport= params => get(url+"home/car_admin/carBudgetExport", params);
//车辆编辑
export const  carEdit= params => get(url+"home/car_admin/carEdit", params);
//修改多数据公里数
export const  updateMoreKms= params => post(url+"home/car_admin/updateMoreKms", params);
//修正公里数
export const  updateKms= params => post(url+"home/car_admin/updateKms", params);
//修改车辆状态
export const  updateCarStatus= params => post(url+"home/car_admin/updateCarStatus", params);
//生成二维码
export const  saveQrcode= params => post(url+"home/car_admin/saveQrcode", params);
//车辆保养
export const  carKeep= params => get(url+"home/car_admin/carKeep", params);
//添加车辆保养
export const  addCarKeep= params => post(url+"home/car_admin/addCarKeep", params);
//编辑车辆保养
export const  editCarKeep= params => post(url+"home/car_admin/editCarKeep", params);
//删除车辆保养
export const  delCarKeep= params => post(url+"home/car_admin/delCarKeep", params);
//加油管理
export const  oilList= params => get(url+"home/car_admin/oilList", params);
//添加/编辑加油管理
export const  addOrEditOil= params => post(url+"home/car_admin/addOrEditOil", params);
//油卡管理
export const  oilCardList= params => get(url+"home/car_admin/oilCardList", params);
//油卡管理-导入加油
export const  oilImport= params => post(url+"home/car_admin/oilImport", params);
//保险管理
export const  insureList= params => get(url+"home/car_admin/insureList", params);
//添加，编辑保险管理
export const  addOrEditInsure= params => post(url+"home/car_admin/addOrEditInsure", params);
//添加，编辑油卡管理
export const  addOrEditOilCard= params => post(url+"home/car_admin/addOrEditOilCard", params);
//删除油卡
export const  delOilCard= params => post(url+"home/car_admin/delOilCard", params);
//事故统计
export const  accidentList= params => get(url+"home/car_admin/accidentList", params);
//添加，编辑事故统计
export const  addOrEditOilAccident= params => post(url+"home/car_admin/addOrEditOilAccident", params);
//数据汇总
export const  dataSumList= params => get(url+"home/car_admin/dataSumList", params);
//数据汇总类型
export const  hzTypeSel= params => get(url+"home/config/hzTypeSel", params);
//数据导出列表
export const  dataSumExportList= params => get(url+"home/car_admin/dataSumExportList", params);
//数据导出
export const  dataExport= params => get(url+"home/car_admin/dataExport", params);

//数据汇总-月份
// export const  dataMonthList= params => get(url+"home/car_admin/dataMonthList", params);
//数据汇总-人员
export const  dataUserList= params => get(url+"home/car_admin/dataUserList", params);
//查看车辆信息-二维码
export const  showCarInfo= params => get(url+"home/car/showCarInfo", params);
//查看提醒
export const  serviceNotice= params => get(url+"home/car_admin/serviceNotice", params);
/*
工作相关
产品查询
*/
//工厂请假审批
export const factoryArea = params => get(emp_url+"home/login/factoryArea", params);
//状态下拉
export const applyStatusSel = params => get(emp_url+"home/sc_leave_manage/applyStatusSel", params);
//列表
export const dataList = params => get(emp_url+"home/sc_leave_manage/manageList", params);
//点击状态查看审批流程
export const flowList = params => get(url+"home/sc_leave_manage/flowList", params);
//销假详情
export const revokeDetail = params => get(url+"home/sc_leave_manage/revokeDetail", params);
//审批详情
export const applyInfo = params => get(emp_url+"home/sc_leave_manage/applyInfo", params);
//提交审批
export const addManage = params => post(url+"home/sc_leave_manage/addManage", params);
//固定资产-状态
export const zcStatusSel = params => get(url+"home/config/zcStatusSel", params);
//固定资产-品牌
export const zcBrandSel = params => get(url+"home/config/zcBrandSel", params);

//固定资产-类别
export const zcTypeSel = params => get(url+"home/config/zcTypeSel", params);
//固定资产-地区
export const zcAreaSel = params => get(url+"home/config/zcAreaSel", params);
//固定资产-列表
export const zcList = params => get(url+"home/zi_chan/zcList", params);
//修改单个字段
export const zcUpdate = params => post(url+"home/zi_chan/zcUpdate", params);

//添加单条资产
export const zcAdd = params => post(url+"home/zi_chan/zcAdd", params);
//提交变动
export const zcAddLog = params => post(url+"home/zi_chan/zcAddLog", params);
//资产日志
export const zcLogList = params => get(url+"home/zi_chan/zcLogList", params);
//分类
export const workProductType = params => get(url+"home/config/goodsCategory", params);
//产品查询 数据列表
export const getProductList = params => get(url+"home/work/commonGoodsList", params);
//产品查询详情
export const commonGoodsDetail = params => get(url+"home/work/commonGoodsDetail", params);
//产品信息查询/危险品--列表
export const getProductInfo = params => get(url+"home/dg_goods/dgGoodsList", params);
//产品信息查询-详情
export const dgGoodsDetail = params => get(url+"home/dg_goods/dgGoodsDetail", params);
//产品信息查询-危险品-添加/删除
export const addOrEditGoods = params => post(url+"home/dg_goods/addOrEditGoods", params);
//产品信息查询-批量导入
export const dgGoodsImport = params => post(url+"home/dg_goods/import", params);
//产品信息-附件上传初始化
export const uploadAttachInit = params => post(url+"home/dg_goods/uploadAttachInit", params);
//产品信息-附件上传文件流
export const uploadStream = params => post(url+"home/dg_goods/uploadStream", params);
//产品信息-附件上传完成
export const uploadAttachFinish = params => post(url+"home/dg_goods/uploadAttachFinish", params);
//危险品附件删除
export const deleteAttach = params => post(url+"home/dg_goods/deleteAttach", params);
//订单业务员列表
export const orderOpList = params => get(url+"home/work/orderOpList", params);
//订单查询
export const orderList = params => get(url+"home/work/orderList", params);
//订单统计
export const workOrderCount = params => get(url+"home/work/orderCount", params);
//排产列表
export const demandList = params => get(url+"home/work/demandList", params);
//排产统计
// export const demandStatistic = params => get(url+"home/work/demandStatistic", params);
//排产导入
export const demandImport = params => post(url+"home/work/demandImport", params);
//添加或编辑排产
export const addOrEditDemand = params => post(url+"home/work/addOrEditDemand", params);
//取消排产
export const delDemand = params => post(url+"home/work/delDemand", params);
//风电列表
export const windList = params => get(url+"home/work/windList", params);
//风电确认
export const addWindHistory = params => get(url+"home/work/addWindHistory", params);
//风电-公司列表
export const windCompany = params => get(url+"home/work/windCompany", params);
//风电基地列表
export const windBase = params => get(url+"home/work/windBase", params);
//添加风电
export const addWind = params => post(url+"home/work/addWind", params);
//编辑风电
export const editWind = params => post(url+"home/work/editWind", params);
//风电导入
export const windImport = params => post(url+"home/work/windImport", params);
//风电-历史数据列表
export const windHistory = params => get(url+"home/work/windHistory", params);
//风电联系人列表
export const windLinkList = params => get(url+"home/work/windLinkList", params);
//风电联系人导入
export const windLinkImport = params => post(url+"home/work/windLinkImport", params);
//添加或编辑风电联系人
export const addOrEditWindLink = params => post(url+"home/work/addOrEditWindLink", params);
//风电设置状态
export const windSetValid = params => post(url+"home/work/windSetValid", params);
//风电密切列表
export const windLinkDegree = params => get(url+"home/config/windLinkDegree", params);
/* 运输管理*/
//收货地址列表
export const receiptAddrList = params => get(url+"home/trans_manage/receiptAddrList", params);
//添加，编辑收货地址
export const addOrEditReceiptAddr = params => post(url+"home/trans_manage/addOrEditReceiptAddr", params);
//更换收货地址
export const addrSetStatus = params => post(url+"home/trans_manage/addrSetStatus", params);
//共享用户地址
export const shareAddrUsers = params => post(url+"home/trans_manage/shareAddrUsers", params);

//省市列表
export const cityList = params => get(url+"home/trans_manage/citySel", params);
//权限省市列表
export const companySel = params => get(url+"home/trans_manage/companySel", params);
export const citySel = params => get(url+"home/trans_manage/citySel", params);
//新增三级联动
export const worldAreaList = params => get(url+"home/config/worldAreaList", params);
//发货地址下拉
export const faHuoAddr = params => get(url+"home/trans_manage/faHuoAddr", params);
//添加,编辑城市
export const editCity = params => post(url+"home/trans_manage/editCity", params);
//删除省市
export const delCity = params => post(url+"home/trans_manage/delCity", params);
//角色权限列表
export const yfRoleList = params => get(url+"home/trans_manage/yfRoleList", params);
//分公司列表
export const companyList = params => get(url+"home/trans_manage/companyList", params);
//公司绑定员工权限列表
export const userCompanyList = params => get(url+"home/trans_manage/userCompanyList", params);
//承运商列表
export const carrierList = params => get(url+"home/trans_manage/carrierList", params);
//绑定承运商
export const bindUserCarrier = params => post(url+"home/trans_manage/bindUserCarrier", params);
//全部承运商列表
export const carrierSelList = params => get(url+"home/trans_manage/carrierSelList", params);

//添加，编辑承运商
export const editCarrier = params => post(url+"home/trans_manage/editCarrier", params);
//删除承运商
export const delCarrier = params => post(url+"home/trans_manage/delCarrier", params);

//基础运费列表
export const jbyfList = params => get(url+"home/trans_manage/jbyfList", params);
//删除基本运费
// export const jbyfList = params => get(url+"home/trans_manage/delJbyf", params);
//
//基础运费详情
export const jbyfDetail = params => get(url+"home/trans_manage/jbyfDetail", params);
//添加，编辑基本运费
export const editJbyf = params => post(url+"home/trans_manage/editJbyf", params);
//删除基本运费
export const delJbyf = params => post(url+"home/trans_manage/delJbyf", params);
//快递下单
export const expressOrder = params => get(url+"home/trans_manage/expressOrder", params);
//寄快递详情
export const sendDetail = params => get(url+"home/trans_manage/sendDetail", params);
//运费单列表
export const yfOrderList = params => get(url+"home/trans_manage/yfOrderList", params);
//添加，编辑运单页面下拉
export const jbyfSelList = params => get(url+"home/trans_manage/jbyfSelList", params);
//查询出库单列表
export const chukuTypeSel = params => get(url+"home/config/chukuTypeSel", params);

//增加出库单--查询列表
export const chuKuList = params => get(url+"home/trans_manage/chuKuList", params);
//查询-运单查询
export const orderSelect = params => get(url+"home/trans_manage/orderSelect", params);
//运单详情
export const getEditOrderData = params => get(url+"home/trans_manage/getEditOrderData", params);
//保存编辑运单
export const editOrder = params => post(url+"home/trans_manage/editOrder", params);
//运费单删除
export const delOrder = params => post(url+"home/trans_manage/delOrder", params);
//编辑运单 获取相关费用
export const getCalcFee = params => post(url+"home/trans_manage/getCalcFee", params);
//追踪列表
export const trackList = params => get(url+"home/trans_manage/trackList", params);
//追踪状态列表
export const zzstateList = params => get(url+"home/trans_manage/zzstateList", params);
//添加，编辑追踪列表
export const addOrEditTrack = params => post(url+"home/trans_manage/addOrEditTrack", params);
//删除追踪列表
export const delTrack = params => post(url+"home/trans_manage/delTrack", params);
//回单扫描
export const uploadImg = params => post(url+"home/trans_manage/uploadImg", params);
//订单审核
export const orderAnalysis = params => get(url+"home/trans_manage/orderAnalysis", params);
//审核结算详情
export const checkAccountDetail = params => post(url+"home/trans_manage/checkAccountDetail", params);
//审核订单
export const checkOrder = params => post(url+"home/trans_manage/checkOrder", params);
//结算订单
export const settleAccount = params => post(url+"home/trans_manage/settleAccount", params);
//数据分析统计
export const orderCount = params => get(url+"home/trans_manage/orderCount", params);
//数据分析打印
export const orderPrintList = params => get(url+"home/trans_manage/orderPrintList", params);
//
export const orderPrintExport = params => get(url+"home/trans_manage/orderPrintExport", params);

//承运商--查看详情
export const jbyfDetail2 = params => get(url+"home/trans_manage/jbyfDetail2", params);
//顺丰打印
export const sfPlacePrint = params => get(url+"home/trans_manage/sfPlacePrint", params);
//收货地址
export const receiveAddr = params => get(url+"home/trans_manage/receiveAddr", params);
//顺丰订单追踪
export const orderSFTrack = params => get(url+"home/trans_manage/orderSFTrack", params);
//取消订单追踪
export const orderSFCancel = params => post(url+"home/trans_manage/orderSFCancel", params);
//双击查看出库单详情
export const orderDetailList = params => get(url+"home/trans_manage/orderDetailList", params);
//运单状态
export const yfOrderStatus = params => get(url+"home/config/yfOrderStatus", params);
//按交货单号查询
export const getListByFbillno = params => get(url+"home/trans_manage/getListByFbillno", params);
//晒选字段列表
export const selectGroupList = params => get(url+"home/trans_manage/selectGroupList", params);

//仓库列表
export const transStockList = params => get(url+"home/trans_manage/stockList", params);
//绑定或取消员工权限
export const bindUserCompany = params => post(url+"home/trans_manage/bindUserCompany", params);
//添加或编辑分公司
export const addOrEditCompany = params => post(url+"home/trans_manage/addOrEditCompany", params);
//取消或绑定仓库权限
export const bindUserStock = params => post(url+"home/trans_manage/bindUserStock", params);
//添加备注
export const editOrderRemark = params => post(url+"home/trans_manage/editOrderRemark", params);
//确认运单列表
export const editOrdeFee = params => post(url+"home/trans_manage/editOrdeFee", params);
/*
积分管理
*/
//积分列表
export const integralList = params => get(url+"home/integral/integralList", params);
//消费列表
export const consumeList = params => get(url+"home/integral/consumeList", params);
//消费列表-记录
export const orderRecode = params => get(url+"home/integral/orderRecode", params);

//添加 编辑消费
export const addOrEditConsume = params => post(url+"home/integral/addOrEditConsume", params);
//关联类别详情
export const linkCodeDetail = params => get(url+"home/integral/linkCodeDetail", params);
//关联固定码
export const linkCode = params => post(url+"home/integral/linkCode", params);
//固定码列表
export const fixCodeList = params => get(url+"home/integral/fixCodeList", params);
//生成固定码
export const saveFixCode = params => get(url+"home/integral/saveFixCode", params);
//流程列表
export const flowCheckList = params => get(url+"home/integral/flowCheckList", params);
//选择流程列表
export const selectFlowList = params => get(url+"home/integral/selectFlowList", params);
//创建审批
export const addCheckFlow = params => post(url+"home/integral/addCheckFlow", params);
//查看审批
export const flowDetail = params => get(url+"home/integral/flowDetail", params);
//提交审批
export const doFlowCheck = params => post(url+"home/integral/doFlowCheck", params);

//确认支付
export const paySubmit = params => post(url+"home/integral/paySubmit", params);
//核销
export const doConsume = params => post(url+"home/integral/doConsume", params);
//删除消费
export const delConsume = params => post(url+"home/integral/delConsume", params);
//消费详情
export const consumeShow = params => get(url+"home/integral/consumeShow", params);
/*
客户拜访
*/
//计划列表
export const planList = params => get(url+"home/visit_plan/planList", params);
//计划详情
export const planDetail = params => get(url+"home/visit_plan/planDetail", params);
//客户列表
export const customerList = params => get(url+"home/visit_plan/companySel", params);
//添加计划-获取分类
export const categoryList = params => get(url+"home/visit_plan/categoryList", params);
//添加计划-切换客户 获取联系人
export const linkList = params => get(url+"home/visit_plan/linkList", params);
//新客户查询
export const localCustomerList = params => get(url+"home/visit_plan/localCompanySel", params);
//提交添加计划
export const addPlan = params => post(url+"home/visit_plan/addPlan", params);
//报告详情
export const reportDetail = params => get(url+"home/visit_plan/reportDetail", params);

//添加联系人
export const editLinkMan = params => post(url+"home/visit_plan/editLinkMan", params);
//删除联系人
export const delLinkMan = params => post(url+"home/visit_plan/delLinkMan", params);
//编辑报告--获取数据
export const planReportDetail = params => get(url+"home/visit_plan/reportDetail", params);
//提交，计划变更报告
export const editPlanReport = params => post(url+"home/visit_plan/editPlanReport", params);
//修改计划时间
export const editPlanDate = params => post(url+"home/visit_plan/editPlanDate", params);
//删除计划
export const delPlan = params => post(url+"home/visit_plan/delPlan", params);

//报告审核
export const reportAudit = params => post(url+"home/visit_plan/reportAudit", params);
//计划审核列表
export const planAuditList = params => get(url+"home/visit_plan/planAuditList", params);
//客户列表
export const companyAddressList = params => get(url+"home/visit_plan/companyAddressList", params);
//客户拜访统计
export const levelCount = params => get(url+"home/visit_plan/levelCount", params);
//获取单条客户信息
export const getCompanyItem = params => get(url+"home/visit_plan/getCompanyItem", params);
//客户分类
export const custTypeSel = params => get(url+"home/visit_plan/custTypeSel", params);

//批注列表
export const planRemarkList = params => get(url+"home/visit_plan/planRemarkList", params);
//添加批注
export const reportRemark = params => post(url+"home/visit_plan/reportRemark", params);
/*
预排期计划
计划列表（BOM）

*/
export const getPageBomList = params => get(url+"home/ready_plan/getPageBomList", params);
//删除物料
export const delMaterials = params => post(url+"home/ready_plan/delMaterials", params);
//获取部件列表
export const getPartsList = params => get(url+"home/ready_plan/getPartsList", params);
//添加预排期计划
export const addReadyPlan = params => post(url+"home/ready_plan/addReadyPlan", params);
//查看物料列表
export const getBomList = params => get(url+"home/ready_plan/getBomList", params);
//计划列表(部件)
export const getPartsPageList = params => get(url+"home/ready_plan/getPartsPageList", params);
//编辑部件
export const editPart = params => post(url+"home/ready_plan/editPart", params);
//删除部件
export const delPart = params => post(url+"home/ready_plan/delPart", params);
//日志
export const getLogList = params => get(url+"home/ready_plan/getLogList", params);
//拨号列表
export const getSpeedDialList = params => get(url+"home/index/getSpeedDialList", params);
//快速拨号记录
export const saveSpeedDial = params => post(url+"home/index/saveSpeedDial", params);
//首页
export const homeIndex = params => get(url+"home/index/index", params);
//当日考勤列表
export const getKqList = params => get(url+"home/index/getKqList", params);
//保存考勤
export const saveKaoqin = params => post(url+"home/index/saveKaoqin", params);
//销售额
export const saleAchieve = params => get(url+"home/index/saleAchieve", params);
//考勤列表
export const showKqList = params => get(url+"home/day_work/showKqList", params);
//发布人列表
export const memList = params => get(url+"home/day_work/memList", params);
//工作日志列表
export const workLogs = params => get(url+"home/day_work/workLogs", params);
//添加工作日志
export const addWorkLog = params => post(url+"home/day_work/addWorkLog", params);
//编辑工作日志
export const editWorkLog = params => post(url+"home/day_work/editWorkLog", params);
//删除工作日志
export const delWorkLog = params => post(url+"home/day_work/delWorkLog", params);
//---------------员工管理---------------------------------

//员工配置
export const userConfig = params => get(url+"home/config/userConfig", params);
//推送详情
export const pushInfos = params => get(url+"home/sc_user/pushInfos", params);
//员工省市
export const userArea = params => get(url+"home/config/userArea", params);
//员工确认信息
export const editPushUser = params => post(url+"home/sc_user/editPushUser", params);
//获取员工年龄
export const getUserAge = params => post(url+"home/sc_user/getUserAge", params);
//官网----------------------------------------------------
//省市列表
export const gwPositionFilter = params => get(url+"home/api/gwPositionFilter", params);
//职位列表
export const gwPositionList = params => get(url+"home/zhao_pin/gwPositionList", params);
//职位编辑/审核获取数据
export const gwPositionItem = params => get(url+"home/zhao_pin/gwPositionItem", params);
//招聘平台职位名称
export const positionTotalSel = params => get(url+"home/zhao_pin/positionTotalSel", params);
//职位编辑
export const gwPositionEdit = params => post(url+"home/zhao_pin/gwPositionEdit", params);
//职位审核
export const gwPositionManage = params => post(url+"home/zhao_pin/gwPositionManage", params);
//简历列表搜索
export const resumeIndexSearch = params => get(url+"home/zhao_pin/resumeIndexSearch", params);
//简历列表
export const resume_list = params => get(url+"home/zhao_pin/resume_list", params);
//批量面试弹窗内容
export const getBatchSendData = params => get(url+"home/zhao_pin/getBatchSendData", params);
//提交批量面试反馈
export const batchSendMessage = params => post(url+"home/zhao_pin/batchSendMessage", params);
//邀请-预览短信、邮件模板
export const sendMessageReview = params => get(url+"home/zhao_pin/sendMessageReview", params);



//点击状态流
export const statusListLog = params => get(url+"home/zhao_pin/statusListLog", params);
//姓名详情
export const resumeDetailShort = params => get(url+"home/zhao_pin/resumeDetailShort", params);
//批量放弃
export const batchGiveUpResume = params => post(url+"home/zhao_pin/batchGiveUpResume", params);
//简历详情
export const resumeDetail = params => get(url+"home/zhao_pin/resumeDetail", params);
//简历详情-未登录可访问
export const viewResumeDetail = params => get(url+"home/api/viewResumeDetail", params);

//保存初筛结果
export const setPassStatus = params => post(url+"home/zhao_pin/setPassStatus", params);

//保存简历
export const editResumeDetail = params => post(url+"home/zhao_pin/editResumeDetail", params);
//邀请数据获取
export const sendMessageData = params => get(url+"home/zhao_pin/sendMessageData", params);
//批量修改状态
export const batchSetStatus = params => post(url+"home/zhao_pin/batchSetStatus", params);

//邀请记录列表
export const sendLogList = params => get(url+"home/zhao_pin/sendLogList", params);
//提交邀请
export const sendMessage = params => post(url+"home/zhao_pin/sendMessage", params);
//基本信息获取
export const editRBase = params => get(url+"home/zhao_pin/editRBase", params);
//基本信息提交
export const submitEditBase = params => post(url+"home/zhao_pin/editRBase", params);
//文件获取
export const getRFile = params => get(url+"home/zhao_pin/getRFile", params);
//文件上传
export const uploadResumeFile = params => post(url+"home/zhao_pin/uploadResumeFile", params);
//文件删除
export const delResumeFile = params => post(url+"home/zhao_pin/delResumeFile", params);
//职位列表
export const editRPosition = params => get(url+"home/zhao_pin/editRPosition", params);
//职位-获取
export const resumePositionBindHr = params => post(url+"home/zhao_pin/resumePositionBindHr", params);
//职位-添加/删除
export const editRPosition1 = params => post(url+"home/zhao_pin/editRPosition", params);
//邮件回复模板获取
export const editResumeReply = params => get(url+"home/zhao_pin/editResumeReply", params);
// //邮件回复-发送
export const sendResumeReply = params => post(url+"home/zhao_pin/editResumeReply", params);
//面试状态-获取
export const editResumeStatus = params => get(url+"home/zhao_pin/editResumeStatus", params);
//面试状态提交
export const submitResumeStatus = params => post(url+"home/zhao_pin/editResumeStatus", params);
//51测评-获取数据
export const cePingTemp = params => get(url+"home/zhao_pin/cePingTemp", params);
//51测评提交
export const sendCePing = params => post(url+"home/zhao_pin/sendCePing", params);
//面试评估获取数据
export const sendEstimate = params => get(url+"home/zhao_pin/sendEstimate", params);
//推送到微信
export const sendInterview = params => post(url+"home/zhao_pin/sendEstimate", params);
//审计核查
export const editHeCha = params => get(url+"home/zhao_pin/tabHeCha", params);
//发送核查文件
export const sendHeCha = params => post(url+"home/zhao_pin/sendHeCha", params);
//查看核查文件
export const heChaData = params => get(url+"home/zhao_pin/heChaData", params);
//人事背调获取数据
export const getBeiDiao = params => get(url+"home/zhao_pin/getBeiDiao", params);
//发送背调文件
export const sendReport = params => post(url+"home/zhao_pin/sendReport", params);
//录用通知获取数据
export const getEditOffer = params => get(url+"home/zhao_pin/getEditOffer", params);
//录用通知-状态
export const offerStatusSel = params => get(url+"home/config/offerStatusSel", params);
//提交录用通知
export const addOffer = params => post(url+"home/zhao_pin/addOffer", params);
//登记表获取数据
export const editDengJi = params => get(url+"home/zhao_pin/editDengJi", params);
//登记表生成图片
export const generateDJCode = params => post(url+"home/zhao_pin/generateDJCode", params);
//到岗获取数据
export const getDaoGang = params => get(url+"home/zhao_pin/getDaoGang", params);
//提交到岗通知
export const submitDaoGang = params => post(url+"home/zhao_pin/DaoGang", params);
//面试评估表详情
export const estimateInfo = params => get(url+"home/zhao_pin/estimateInfo", params);
//面试评估表提交
export const editResumeEstimate = params => post(url+"home/zhao_pin/editResumeEstimate", params);

//员工分页列表
export const userPage = params => get(url+"home/sc_user/userPage", params);
//页面消息提醒
export const rollTip = params => get(url+"home/sc_user/rollTip", params);
//导出列表
export const exportIndex = params => get(url+"home/sc_user/exportIndex", params);
//提交导出列表
export const exportUser = params => post(url+"home/sc_user/exportUser", params);
//员工导入列表
export const importList = params => get(url+"home/sc_user/importList", params);
//员工导入信息
export const importUser = params => post(url+"home/sc_user/importUser", params);
//员工推送列表
export const pushList = params => get(url+"home/sc_user/pushList", params);
//提交推送数据
export const batchPush = params => post(url+"home/sc_user/batchPush", params);
//按钮权限
export const btnIndex = params => get(url+"home/sc_user/btnIndex", params);
//员工审核列表
export const userCheckList = params => get(url+"home/sc_user/userCheckList", params);
//审核提醒
export const checkTip = params => get(url+"home/sc_user/checkTip", params);
//重新推送
export const pushAgain = params => post(url+"home/sc_user/pushAgain", params);
//取消推送
export const pushCancel = params => post(url+"home/sc_user/pushCancel", params);
//不再提醒
export const notTip = params => get(url+"home/sc_user/notTip", params);
//员工审核详情
export const checkDetail = params => post(url+"home/sc_user/checkDetail", params);
//审核操作
export const doCheck = params => post(url+"home/sc_user/doCheck", params);

//基本配置列表
export const configList = params => get(url+"home/sc_user/configList", params);
//添加、编辑配置
export const addOrEditConf = params => post(url+"home/sc_user/addOrEditConf", params);
//配置协议列表
export const agreeTypeList = params => get(url+"home/sc_user/agreeTypeList", params);
//配置协议列表-添加
export const addAgreeType = params => post(url+"home/sc_user/addAgreeType", params);
//配置协议列表-删除
export const delAgreeType = params => post(url+"home/sc_user/delAgreeType", params);
//员工历史记录列表
export const historyList = params => get(url+"home/sc_user/historyList", params);
//员工历史记录详情
export const historyDetail = params => get(url+"home/sc_user/historyDetail", params);
//员工报表
export const userReport = params => get(url+"home/sc_user/userReport", params);
//实时人数报表
export const realTimeReport = params => get(url+"home/sc_user/realTimeReport", params);
//待办事项
export const daiBanList = params => get(url+"home/index/daiBanList", params);















//生产系统相关

//配置列表
export const confList = params => get(url+"home/emp/confList", params);
//人员登录信息
export const userList = params => get(url+"home/emp/userList", params);
//获取员工登录名称
export const getLoginName = params => get(url+"home/emp/getLoginName", params);
//获取员工信息列表
export const getScUserList = params => get(url+"home/emp/getScUserList", params);
//人员登录详情
export const userDetail = params => get(url+"home/emp/userDetail", params);
//员工详情
export const scUserDetail = params => get(url+"home/sc_user/userDetail", params);
//人员登录添加/编辑
export const addOrEditUser = params => post(url+"home/emp/addOrEditUser", params);
//人员登录借调接口
export const moveUser = params => post(url+"home/emp/moveUser", params);
//添加员工信息
export const addUser = params => post(url+"home/sc_user/addUser", params);
//编辑员工信息
export const editUser = params => post(url+"home/sc_user/editUser", params);
//员工信息详情
export const sc_userDetail = params => get(url+"home/sc_user/userDetail", params);
//获取OA用户
export const OAUsers = params => get(url+"home/sc_user/OAUsers", params);
//学历列表
export const eduList = params => get(url+"home/sc_user/eduList", params);
//删除学历
export const delEdu = params => post(url+"home/sc_user/delEdu", params);
//添加/编辑学历
export const addOrEditEdu = params => post(url+"home/sc_user/addOrEditEdu", params);
//职称列表
export const titleList = params => get(url+"home/sc_user/titleList", params);
//添加/编辑职称
export const addOrEditTitle = params => post(url+"home/sc_user/addOrEditTitle", params);
//删除职称
export const delTitle = params => post(url+"home/sc_user/delTitle", params);
//家庭列表
export const familyList = params => get(url+"home/sc_user/familyList", params);
//添加/编辑家庭
export const addOrEditFamily = params => post(url+"home/sc_user/addOrEditFamily", params);
//删除家庭
export const delFamily = params => post(url+"home/sc_user/delFamily", params);
//工作经历列表
export const workList = params => get(url+"home/sc_user/workList", params);
//添加/编辑工作经历
export const addOrEditWork = params => post(url+"home/sc_user/addOrEditWork", params);
//删除工作经历
export const delWork = params => post(url+"home/sc_user/delWork", params);
//试用期详情
export const  trailDetail= params => get(url+"home/sc_user/trailDetail", params);
//添加/编辑试用期
export const  addOrEditTrail= params => post(url+"home/sc_user/addOrEditTrail", params);
//试用期转正月度总结
export const  trailSubmitList= params => get(url+"home/sc_user/trailSubmitList", params);
//编辑月度总结
export const  editSubmit= params => post(url+"home/sc_user/editSubmit", params);
//删除试用期报告
export const  delTrailSubmit= params => post(url+"home/sc_user/delTrailSubmit", params);
//上传单个文件
export const  userAddFile= params => post(url+"home/sc_user/addFile", params);
//上传多个文件
export const  multiFile= params => post(url+"home/sc_user/multiFile", params);
//
export const  delFile= params => post(url+"home/sc_user/delFile", params);


//月报告详情
export const  submitDetail= params => get(url+"home/sc_user/submitDetail", params);
//评价表详情
export const  submitEval= params => get(url+"home/sc_user/submitEval", params);
//访谈表
export const  submitInter= params => get(url+"home/sc_user/submitInter", params);
//异动列表
export const  changeList= params => get(url+"home/sc_user/changeList", params);
//添加或编辑移动
export const  addOrEditChange= params => post(url+"home/sc_user/addOrEditChange", params);
//删除异动
export const  delChange= params => post(url+"home/sc_user/delChange", params);
//合同列表
export const  contractList= params => get(url+"home/sc_user/contractList", params);
//检查合同编号
export const  checkContNos= params => get(url+"home/sc_user/checkContNos", params);
//获取合同次数
export const  getContNums= params => get(url+"home/sc_user/getContNums", params);
//获取合同结束日期
export const  getContEnd= params => get(url+"home/sc_user/getContEnd", params);
//添加或编辑合同
export const  addOrEditCont= params => post(url+"home/sc_user/addOrEditCont", params);
//失效合同
export const  delCont= params => post(url+"home/sc_user/delCont", params);
//协议列表
export const  agreeList= params => get(url+"home/sc_user/agreeList", params);
//添加编辑协议
export const  addOrEditAgree= params => post(url+"home/sc_user/addOrEditAgree", params);
//证件列表
export const  credentList= params => get(url+"home/sc_user/credentList", params);
//添加编辑证件
export const  addOrEditCredent= params => post(url+"home/sc_user/addOrEditCredent", params);
//休产假
export const  materList= params => get(url+"home/sc_user/materList", params);
//添加编辑产假
export const  addOrEditMater= params => post(url+"home/sc_user/addOrEditMater", params);
//删除休产假
export const  delMater= params => post(url+"home/sc_user/delMater", params);
//意外险
export const  userAccidentList= params => get(url+"home/sc_user/accidentList", params);
//添加编辑意外险
export const  addOrEditAccident= params => post(url+"home/sc_user/addOrEditAccident", params);
//删除意外险
export const  delAccident= params => post(url+"home/sc_user/delAccident", params);
//入职列表
export const  entryList= params => get(url+"home/sc_user/entryList", params);
//离职列表
export const  quitList= params => get(url+"home/sc_user/quitList", params);
//删除离职
export const  delQuit= params => post(url+"home/sc_user/delQuit", params);
//附件详情
export const  fileDetail= params => get(url+"home/sc_user/fileDetail", params);
//标记保存
export const  fileSave= params => post(url+"/home/sc_user/fileSave", params);
//添加编辑离职
export const  addOrEditQuit= params => post(url+"home/sc_user/addOrEditQuit", params);
//辞职详情
export const  czDetail= params => get(url+"home/sc_user/czDetail", params);
//解雇详情
export const  jgDetail= params => get(url+"home/sc_user/jgDetail", params);
//离职详情
export const  quitDetail= params => get(url+"home/sc_user/quitDetail", params);
//春节休假推送
export const  userHolidayTsList= params => get(url+"home/sc_user/userHolidayTsList", params);
//推送休假数据
export const  holidayTs= params => post(url+"home/sc_user/holidayTs", params);

//春节前后日期数据
export const  holidayDateSel= params => get(url+"home/config/holidayDateSel", params);
//获取休假天数/小时数
export const  holidayDays= params => get(url+"home/config/holidayDays", params);
//员工提交休假数据
export const  saveHolidayDate= params => post(url+"home/user/saveHolidayDate", params);
//休假统计
export const  userHolidayList= params => get(url+"home/sc_user/userHolidayList", params);

//登记表
export const  userRegisterList= params => get(url+"home/zhao_pin/userRegisterList", params);
//登记表-确认
export const  setFormalStatus= params => post(url+"home/zhao_pin/setFormalStatus", params);
//登记表-详情
export const  registerDetail= params => get(url+"home/zhao_pin/registerDetail", params);
//模板列表
export const  messageTempList= params => get(url+"home/zhao_pin/messageTempList", params);
//模板列表-预览
export const  viewMessageTemp= params => get(url+"home/zhao_pin/viewMessageTemp", params);
//招聘团队产出
export const  countZp= params => get(url+"home/zhao_pin/countZp", params);
//招聘渠道效率
export const  countPlat= params => get(url+"Home/zhao_pin/countPlat", params);
//统计-详情
export const  countUserList= params => get(url+"home/zhao_pin/countUserList", params);
//平台
export const  zpPlatTypeSel= params => get(url+"home/config/zpPlatTypeSel", params);
//平台表下拉
export const  zpEstimateConfSel= params => get(url+"home/config/zpEstimateConfSel", params);
//职位列表
export const  positionTotalList= params => get(url+"home/zhao_pin/positionTotalList", params);
//绑定模板
export const  bindEstimateConf= params => post(url+"home/zhao_pin/bindEstimateConf", params);
//负责人
export const  zpHrSel= params => get(url+"home/config/zpHrSel", params);
//绑定负责人
export const  positionBindHr= params => post(url+"home/zhao_pin/positionBindHr", params);
//绑定部门下拉
export const  zpDepartmentSel= params => get(url+"home/config/zpDepartmentSel", params);
//绑定部门
export const  positionBindDept= params => post(url+"home/zhao_pin/positionBindDept", params);
//职位管理-评估模板列表
export const  estimateConfList= params => get(url+"home/zhao_pin/estimateConfList", params);
//职位管理-模板详情
export const  estimateConfDetail= params => get(url+"home/zhao_pin/estimateConfDetail", params);
//职位管理-添加/编辑模板
export const  editEstimateConf= params => post(url+"home/zhao_pin/editEstimateConf", params);
//职位管理-评估模板 复制
export const  copyEstimate= params => post(url+"home/zhao_pin/copyEstimate", params);
//职位管理-评估模板 删除
export const  delEstimate= params => post(url+"home/zhao_pin/delEstimate", params);
//第三方职位列表
export const  positionRegionList= params => get(url+"home/zhao_pin/positionRegionList", params);
//前程无忧职位详情
export const  wyPositionDetail= params => get(url+"home/zhao_pin/wyPositionDetail", params);
//智联招聘职位详情
export const  zlPositionDetail= params => get(url+"home/zhao_pin/zlPositionDetail", params);
//更新前程/智联职位
export const  positionUpdate= params => get(url+"home/zhao_pin/positionUpdate", params);
//录用通知列表
export const  offerList= params => get(url+"home/zhao_pin/offerList", params);
//录用通知-审核
export const  offerManage= params => post(url+"home/zhao_pin/offerManage", params);
//发录用文件
export const  sendOffer= params => post(url+"home/zhao_pin/sendOffer", params);
//删除录用文件
export const  delOffer= params => post(url+"home/zhao_pin/delOffer", params);
//放弃录用文件
export const  giveUpOffer= params => post(url+"home/zhao_pin/giveUpOffer", params);
//编辑详情
export const  offerEditData= params => get(url+"home/zhao_pin/offerEditData", params);
//提交编辑
export const  editOffer= params => post(url+"home/zhao_pin/editOffer", params);
//到岗通知列表
export const  daoGangList= params => get(url+"home/zhao_pin/daoGangList", params);
//添加背调报告
// export const  getEditReportData= params => get(url+"home/zhao_pin/getEditReportData", params);
//添加报告
export const  editReport= params => post(url+"home/zhao_pin/editReport", params);
//查看报告
export const  getReport= params => get(url+"home/zhao_pin/getReport", params);
//添加简历-搜索条件
export const  getAddResume= params => get(url+"home/zhao_pin/getAddResume", params);
//添加简历-联系电话失去焦点
export const  checkLxdh= params => get(url+"home/zhao_pin/checkLxdh", params);
//添加简历-邮箱失去焦点
export const  checkEmail= params => get(url+"home/zhao_pin/checkEmail", params);
//添加简历-添加职位
export const  addResumePosition= params => post(url+"home/zhao_pin/addResumePosition", params);
//添加简历-提交
export const  addResume= params => post(url+"home/zhao_pin/addResume", params);
//录用通知签名
export const  offerInfo= params => get(url+"home/api/offerInfo", params);
//签名提交
export const  uploadOfferSign= params => post(url+"home/api/uploadOfferSign", params);
//审计上传核查文件
export const  heChaFile= params => get(url+"home/zhao_pin/heChaFile", params);
//职位列表
export const  positionRegionTotalList= params => get(url+"home/zhao_pin/positionRegionTotalList", params);
//下载简历
export const  pullRegionResume= params => get(url+"home/zhao_pin/pullRegionResume", params);
//产品预测
export const  workCompanyList= params => get(url+"home/product_predict/companyList", params);
//预测-排序依据
export const  predictSortSel= params => get(url+"home/config/predictSortSel", params);
//预测-列表标题中月份数据
export const  predictMonthSel= params => get(url+"home/config/predictMonthSel", params);
//预测列表
export const  predictTotalList= params => get(url+"home/product_predict/predictTotalList", params);
//展开预测数据
export const  predictListDetail= params => get(url+"home/product_predict/predictListDetail", params);
//预测-检查库存
export const  predictMultiCheck= params => post(url+"home/product_predict/predictMultiCheck", params);
//预测-保存
export const  predictEdit= params => post(url+"home/product_predict/predictEdit", params);
//预测-查看-日期
export const  predictMStageSel= params => get(url+"home/config/predictMStageSel", params);
//预测-查看列表
export const  predictCommitList= params => get(url+"home/product_predict/predictCommitList", params);
//检查库存
export const  predictOneCheck= params => get(url+"home/product_predict/predictOneCheck", params);
//产品预测审核
export const  manageList= params => get(url+"home/product_predict/manageList", params);
//审核记录
export const  manageLogList= params => get(url+"home/product_predict/manageLogList", params);
//点击审核获取数据
export const  getPredictCheck= params => get(url+"home/product_predict/getPredictCheck", params);
//提交审核
export const  manageSave= params => post(url+"home/product_predict/manageSave", params);
//简历附件
export const  resumeAttach= params => get(url+"home/api/resumeAttach", params);

//---***新增车辆管理
//车辆列表-状态
export const  carStatusSel= params => get(url+"home/config/carStatusSel", params);
//车辆列表
export const  carInfoList= params => get(url+"home/car_admin/carInfoList", params);
//锁车状态
export const  carLockSel= params => get(url+"home/config/carLockSel", params);
//基本信息
export const  carInfo= params => get(url+"home/car_admin/carInfo", params);
//油卡所属公司
export const  carCompanySel= params => get(url+"home/config/carCompanySel", params);
//ETC列表
export const  etcList= params => get(url+"home/car_admin/etcList", params);
//车辆维护-ETC列表
export const  etcUsedList= params => get(url+"home/car_admin/etcUsedList", params);
//车辆维护-导出标题
export const  carExportTitle= params => get(url+"home/car_admin/carExportTitle", params);
//ETC绑定行驶记录
export const  usedAddEtc= params => post(url+"home/car_admin/usedAddEtc", params);
//解绑行驶记录
export const  usedDelEtc= params => post(url+"home/car_admin/usedDelEtc", params);
//车辆汇总列表
export const  dataHzList= params => get(url+"home/car_admin/dataHzList", params);
//违章下拉
export const  wzStatus= params => get(url+"home/config/wzStatus", params);
//违章管理-列表
export const  weiZhangList= params => get(url+"home/car_admin/weiZhangList", params);
//添加、编辑违章
export const  weiZhangEdit= params => post(url+"home/car_admin/weiZhangEdit", params);
//快速查询使用记录
// export const  timeUsedList= params => get(url+"home/car_admin/timeUsedList", params);
//违章推送
export const  weiZhangTip= params => post(url+"home/car_admin/weiZhangTip", params);
//管理员确认
export const  weiZhangDeal= params => post(url+"home/car_admin/weiZhangDeal", params);
//违章详情
export const  weiZhangDetail= params => get(url+"home/car/weiZhangDetail", params);
//提交违章详情
export const  submitWeiZhangDeal= params => post(url+"home/car/weiZhangDeal", params);
//事故统计-记录单
export const  accidentDetail= params => get(url+"home/car/accidentDetail", params);
//驾驶人提交事故信息
export const  accidentEdit= params => post(url+"home/car/accidentEdit", params);
//管理员确认
export const  accidentConfirm= params => post(url+"home/car_admin/accidentConfirm", params);
//管理员退回
export const  accidentBack= params => post(url+"home/car_admin/accidentBack", params);
//事故统计推送
export const  accidentTip= params => post(url+"home/car_admin/accidentTip", params);
//导入ETC
export const  etcImport= params => post(url+"home/car_admin/etcImport", params);
//车辆报表
export const  dataReport= params => get(url+"home/car_admin/dataReport", params);
//附件列表
export const  carFileList= params => get(url+"home/car_admin/carFileList", params);
//附件上传
export const  addCarFile= params => post(url+"home/car_admin/addCarFile", params);
//删除附件
export const  delCarFile= params => post(url+"home/car_admin/delCarFile", params);
//使用人删除附件
export const  wzDelFile= params => post(url+"home/car/wzDelFile", params);
//车辆变更列表
export const  carChangeList= params => get(url+"home/car_admin/carChangeList", params);
//提交变更
export const  addCarChange= params => post(url+"home/car_admin/addCarChange", params);
//分类列表
export const  offCategoryList= params => get(url+"home/office_product/categoryList", params);
//添加、编辑分类
export const  categoryEdit= params => post(url+"home/office_product/categoryEdit", params);
//油卡状态
export const  oilCardStatus= params => get(url+"home/config/oilCardStatus", params);
//违章导入
export const  weiZhangImport= params => post(url+"home/car_admin/weiZhangImport", params);
//
export const  accidentDelFile= params => post(url+"home/car/accidentDelFile", params);
//etc使用人保存
export const  etcUsedEdit= params => post(url+"home/car_admin/etcUsedEdit", params);
//etc卡列表
export const  etcCardList= params => get(url+"home/car_admin/etcCardList", params);
//
export const  etcCardEdit= params => post(url+"home/car_admin/etcCardEdit", params);
//我的行驶记录-私用
export const  myPrivateUsedList= params => get(url+"home/car/myPrivateUsedList", params);
//我的行驶记录-公用
export const  myPublicUsedList= params => get(url+"home/car/myPublicUsedList", params);
//全部申请-公用
export const  allPublicList= params => get(url+"home/car/allPublicList", params);
//全部申请-私用
export const  allPrivateList= params => get(url+"home/car/allPrivateList", params);
//
export const  carUsedList= params => get(url+"home/car_admin/carUsedList", params);
//汇总统计
export const  resumeNumHZ= params => get(url+"home/zhao_pin/resumeNumHZ", params);
//应聘人员-员工登记表-省市
export const  userArea2= params => get(url+"home/api/userArea", params);
//获取登记表数据
export const  api_userDetail= params => get(url+"home/api/userDetail", params);
//下拉
export const  dengjiConfig= params => get(url+"home/api/dengjiConfig", params);
//点击职位
export const  getApiFile= params => get(url+"home/zhao_pin/getApiFile", params);
//提交登记表数据
export const  addUserBase= params => post(url+"home/api/addUserBase", params);
//获取文件列表
export const  userFileList= params => get(url+"home/api/userFileList", params);
//提交文件列表
export const  uploadUserFile= params => post(url+"home/api/uploadUserFile", params);
//删除单个文件
export const  delUserFile= params => post(url+"home/api/delUserFile", params);
//快递定价
export const  expressPrice= params => get(url+"home/trans_manage/expressPrice", params);
//快递导入
export const  expressImport= params => post(url+"home/trans_manage/expressImport", params);
//确认完成登记表
export const  finishRegister= params => post(url+"home/api/finishRegister", params);
//获取员工分机号
export const  getIpPhone= params => get(url+"home/sc_user/getIpPhone", params);
//获取员工系统列表
export const  userXtList= params => get(url+"home/sc_user/userXtList", params);
//车型下拉
export const  carNameSel= params => get(url+"home/config/carNameSel", params);
//获取转正日期
export const  getConfirmDate= params => get(url+"home/sc_user/getConfirmDate", params);
//产品列表
export const  predictProductList= params => get(url+"home/product_predict/predictProductList", params);
//产品预测值列表
export const  productWeekPredict= params => get(url+"home/product_predict/productWeekPredict", params);
//周次预测值提交
export const  weekPredictEdit= params => post(url+"home/product_predict/weekPredictEdit", params);
//客户列表查看
export const  weekUserPredict= params => get(url+"home/product_predict/weekUserPredict", params);
//日志按钮
export const  weekLogList= params => get(url+"home/product_predict/weekLogList", params);
//产品全部预测
export const  weekTotalPredict= params => get(url+"home/product_predict/weekTotalPredict", params);
//上传合同附件
export const  addContFile= params => post(url+"home/sc_user/addContFile", params);
//日期范围下拉
export const  pDateRange= params => get(url+"home/config/pDateRange", params);
//获取周次
export const  dateWeekNum= params => get(url+"home/product_predict/dateWeekNum", params);
//核查确认
export const  confirmHeCha= params => post(url+"home/zhao_pin/confirmHeCha", params);
//核查提交
export const  LawEditHeCha= params => post(url+"home/zhao_pin/LawEditHeCha", params);
//驾驶技术考核列表
export const  myExamList= params => get(url+"home/car/myExamList", params);
//状态下拉
export const  carExamStatus= params => get(url+"home/config/carExamStatus", params);
//驾驶考试审批
export const  examList= params => get(url+"home/car/examList", params);
//添加申请
export const  examApply= params => post(url+"home/car/examApply", params);
//监考员
export const  carJkUser= params => get(url+"home/config/carJkUser", params);
//审批提交
export const  examShenHe= params => post(url+"home/car/examShenHe", params);
//车辆下拉
export const  allCarList= params => get(url+"home/config/carList", params);
//考试结果提交
export const  addExamRes= params => post(url+"home/car/addExamRes", params);
//考车申请单
export const  examDetail= params => get(url+"home/car/examDetail", params);
//
export const  delExam= params => post(url+"home/car/delExam", params);
//报价登录
export const  blockLogin= params => post(url+"home/login/blockLogin", params);
//报价退出登录
export const  blockLoginOut= params => get(url+"home/login/blockLoginOut", params);

//报价-首页配置
export const  indexData= params => get(url+"home/block_price/indexData", params);
//报价列表分页
export const  computeList= params => get(url+"home/block_price/computeList", params);
//BLOCK成本计算
export const  blockPrice= params => post(url+"home/block_price/blockPrice", params);
//数据计算
export const  productCalc= params => post(url+"home/block_price/productCalc", params);
//修改销售总金额
export const  productCalcByTotal= params => post(url+"home/block_price/productCalcByTotal", params);
//添加报价
export const  addComputeData= params => post(url+"home/block_price/addComputeData", params);
//辅助报价
export const  fzTotalPrice= params => get(url+"home/block_price/fzTotalPrice", params);
//报价单
export const  quotationList= params => get(url+"home/block_price/quotationList", params);
//回单列表
export const  receiptList= params => get(url+"home/trans_manage/receiptList", params);
//确认回单
export const  isReceipt= params => post(url+"home/trans_manage/isReceipt", params);
//行驶证借用列表
export const  myLicenceBorrow= params => get(url+"home/car/myLicenceBorrow", params);
//行驶证借用添加
export const  addLlicenceBorrow= params => post(url+"home/car/addLlicenceBorrow", params);
//行驶证借用详情
export const  licenceBorrowDetail= params => get(url+"home/car/licenceBorrowDetail", params);
//行驶证借用删除
export const  licenceBorrowDel= params => post(url+"home/car/licenceBorrowDel", params);
//
export const  carLBorrowStatus= params => get(url+"home/config/carLBorrowStatus", params);
//行驶证借用审批列表
export const  licenceBorrowList= params => get(url+"home/car/licenceBorrowList", params);
//附件上传
export const  carLicenceFile= params => post(url+"home/car/carLicenceFile", params);
//审批/确认领取/确认归还
export const  licenceBorrowManage= params => post(url+"home/car/licenceBorrowManage", params);
//使用情况
export const  carUseSel= params => get(url+"home/config/carUseSel", params);
//圣诞愿望
export const  activityIndex= params => get(url+"home/login/activityIndex", params);
//愿望单保存
export const  MActInfoSave= params => post(url+"home/index/MActInfoSave", params);
//手机抽奖页
export const  activityDraw= params => get(url+"home/login/activityDraw", params);
//抽奖按钮
export const  MDrawSave= params => get(url+"home/index/MDrawSave", params);
//考试时间
export const  editExamPlanTime= params => post(url+"home/car/editExamPlanTime", params);
//圣诞活动首页
export const  activityIndex2= params => get(url+"home/Activity/index", params);
//抽奖清单
export const  drawInfo= params => get(url+"home/Activity/drawInfo", params);


























































































































































































































